import React from "react";
import { Share } from "@molecules";
import { Container, Text, ButtonAlt } from "@atoms";
import ContentBuilder from "./ContentBuilder";

const JobBody = ({ blocks, meta }) => {
  const { title, url } = meta;
  return (
    <section className="py-12 md:py-28">
      <Container variant="xs">
        <div className="space-y-12 md:space-y-18">
          <ButtonAlt to="about/jobs" reverse>
            Back to Jobs
          </ButtonAlt>
          <Text variant="h1">{title}</Text>
        </div>
      </Container>
      <ContentBuilder blocks={blocks} />
      <Container variant="xs">
        <Share url={url} />
      </Container>
    </section>
  );
};

JobBody.defaultProps = {};

export default JobBody;
